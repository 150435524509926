import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import SRC_EYE from "../assets/img/eye.svg";
import { InputField } from "./ui/Forms";

import swal from "sweetalert";

import { API_ROOT } from "../api";
import axios from "axios";

export const Signup = (props) => {
  const { TRANSLATE, history, onStartUser, LANGUAGE } = props;

  const [NICKNAME, setNickname] = useState("");
  const [PASSWORD, setPassword] = useState("");
  const [nicknameError, setNicknameError] = useState(""); // Validation error for nickname
  const [isLoading, setLoading] = useState(false);

  const onKeyUp = (event) => {
    event.preventDefault();

    if (event.keyCode === 13) {
      event.target.blur();
      onSignup();
    }
  };

  const sanitizeNickname = (value) => {
    // Allow only letters, numbers, and underscores
    const validCharacters = value.replace(/[^a-zA-Z0-9_]/g, "");
    if (value !== validCharacters) {
      setNicknameError("Only letters, numbers, and underscores are allowed.");
    } else {
      setNicknameError("");
    }
    return validCharacters?.toLowerCase();
  };

  const onSignup = () => {
    const FORM = {
      nickname: NICKNAME.trim(),
      password: PASSWORD.trim(),
    };

    if (NICKNAME.length < 3) {
      swal("Signup", "Nickname must be at least 3 characters long.", "warning");
      return;
    }

    if (!isValidForm(FORM, TRANSLATE)) {
      return;
    }

    setLoading(true);

    axios
      .post(API_ROOT + "/users/signup", FORM)
      .then((response) => {
        setLoading(false);

        localStorage.setItem("jwt_token", response.data.jwt_token);
        onStartUser();
        history.push("/" + LANGUAGE + "/settings");
      })
      .catch((error) => {
        setLoading(false);

        if (!error || !error.response) {
          return swal("Signup", TRANSLATE.error_invalid_credentials);
        }

        const error_data = error.response.data;
        const error_message = error_data.error;

        if (error_message === "missing_password") {
          return swal(TRANSLATE.wd_signup, TRANSLATE.error_missing_password);
        } else if (error_message === "missing_email") {
          return swal(TRANSLATE.wd_signup, TRANSLATE.error_invalid_email);
        } else if (error_message === "user_already_exists") {
          return swal(TRANSLATE.wd_signup, TRANSLATE.error_already_exists);
        }

        return swal(TRANSLATE.wd_signup, TRANSLATE.error_invalid_credentials);
      });
  };

  return (
    <>
      <div className="fixed flex flex-col bg-zinc-800 text-white h-full w-full z-[9999] top-0 left-0">
        <div className="w-full h-[68px] flex bg-zinc-800 text-white justify-between items-center px-global flex-shrink-0">
          <Link to="/" className="font-semibold tracking-wider text-xl text-yellow-500">
            CALI<span className="text-white">STAKES</span>
          </Link>
          <Link to="/login" className="text-yellow-500 font-semibold text-sm">
            Login
          </Link>
        </div>
        <div className="w-full p-8 h-full overflow-auto items-center justify-center flex flex-col">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full mb-12 flex-col gap-3 items-center justify-center text-center"
          >
            <h1 className="text-4xl font-semibold">Hello there!</h1>
            <h3 className="text-lg text-slate-400">Please create an account to continue</h3>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            className="w-full gap-3 flex flex-col"
          >
            {/* Nickname Input */}
            <InputField
              label="Nickname"
              inputProps={{
                value: NICKNAME,
                placeholder: "Type Here",
                onChange: (e) => {
                  const sanitizedValue = sanitizeNickname(e.target.value);
                  setNickname(sanitizedValue);
                },
                onKeyUp: (e) => onKeyUp(e),
              }}
            />
            {/* Display nickname error */}
            {nicknameError && <p className="text-red-500 text-sm">{nicknameError}</p>}

            {/* Password Input */}
            <InputField
              label="Password"
              id="password"
              type="password"
              hasIcon="true"
              icon={SRC_EYE}
              inputProps={{
                value: PASSWORD,
                placeholder: "Type Here",
                onChange: (e) => setPassword(e.target.value),
                onKeyUp: (e) => onKeyUp(e),
              }}
            />

            {isLoading ? (
              <div className="w-full rounded-xl bg-zinc-500 text-white shadow-2xl p-4 text-center mt-3">
                Loading...
              </div>
            ) : (
              <div
                className="w-full rounded-xl bg-yellow-500 text-white shadow-2xl p-4 text-center mt-3"
                onClick={() => onSignup()}
              >
                Sign up
              </div>
            )}
          </motion.div>
        </div>
      </div>
    </>
  );
};

const isValidForm = (FORM, TRANSLATE) => {
  if (!FORM.nickname || FORM.nickname.length < 3) {
    swal("Signup", "Nickname must be at least 3 characters long.", "warning");
    return false;
  }

  if (!FORM.password || FORM.password.length < 6) {
    swal("Signup", "Password must be at least 6 characters long.", "warning");
    return false;
  }

  return true;
};

export default Signup;