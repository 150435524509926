
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { motion, useScroll } from 'framer-motion'


import SRC_LOGO from "../assets/img/landing/logo-1.svg"
import SRC_LOGO_DARK from "../assets/img/landing/logo-2.svg"
import SRC_HERO from "../assets/img/landing/hero.jpg"
import SRC_PHONE from "../assets/img/landing/iphone.png"
import SRC_ACTIVITY from "../assets/img/landing/activity.png"
import SRC_POST from "../assets/img/landing/post.png"
import SRC_INSTRUCTORS from "../assets/img/landing/instructors.jpg"
import SRC_MAP from "../assets/img/landing/map.jpg"
import SRC_BUY from "../assets/img/landing/buy-rent.jpg"
import SRC_DOWNLOAD from "../assets/img/landing/download.jpg"

import SRC_WAVE from "../assets/img/wave.svg"
import SRC_TOMAS from "../assets/img/tomas.jpg"
import SRC_TIME from "../assets/img/time.svg"
import SRC_STAR from "../assets/img/star-empty.svg"

import SRC_GOOGLE from "../assets/img/google.svg"
import SRC_APPLE from "../assets/img/apple.svg"
import LandingPageOdd from '../pages/Landing'


const Landing = () => {
    return (
        <div id="landingScroll" className='w-full bg-white  relative z-[999999999]'>
            {/* <Navbar /> */}
            {/* <Hero /> */}
            {/* <Sessions />
            <Instructors />
            <Buy />
            <Map />
            <Download /> */}
            {/* <footer className=' bg-white w-full  px-6 py-16 text-sm border-t border-slate-200 text-center flex flex-col justify-center'>
                <ul className='flex flex-col md:flex-row flex-shrink-0 justify-center gap-8 text-xs'>
                    <li><Link className='hover:text-sky-500' to="/">Terms & Conditions</Link></li>
                    <li><Link className='hover:text-sky-500' to="/privacy">Privacy Policy</Link></li>
                    <li><Link className='hover:text-sky-500' to="/cancellation">Cancellation policy</Link></li>
                </ul>
                <div className='font-bebas mt-6'>&copy; Calistakes 2024</div>
            </footer> */}

            <LandingPageOdd />

        </div>
    )
}

const Navbar = () => {
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({
            behavior: 'smooth'
        });
    };

    const { scrollYProgress } = useScroll();
    const [showModal, setShowModal] = useState(false);

    const handleModal = () => {
        setShowModal(true);
    };

    const [scrolledDown, setScrolledDown] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolledDown(true);
            } else {
                setScrolledDown(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <motion.div className={`w-full fixed top-0 left-0 z-50 flex flex-row justify-between items-center transition-all ${scrolledDown ? 'bg-white/90 backdrop-blur-sm p-4 text-neutral-900' : 'bg-transparent p-6 text-white'}`}>
            <nav className='rounded-2xl flex flex-row md:p-4 z-50'>
                <Link onClick={() => scrollToSection("hero")} to="#">
                    {scrolledDown ? (
                        <img className='w-16' src={SRC_LOGO_DARK} alt="Logo" />
                    ) : (
                        <img className='w-16' src={SRC_LOGO} alt="Logo" />
                    )}
                </Link>
            </nav>
            <ul className='hidden md:flex flex-row flex-shrink-0 mr-auto ml-10 justify-center font-bebas gap-x-8'>
                <li><Link className='hover:text-sky-500' onClick={() => scrollToSection("sessions")} to="#">Sessions</Link></li>
                <li><Link className='hover:text-sky-500' onClick={() => scrollToSection("instructors")} to="#">Instructors</Link></li>
                <li><Link className='hover:text-sky-500' onClick={() => scrollToSection("buy")} to="#">Buy & Rent</Link></li>
                <li><Link className='hover:text-sky-500' onClick={() => scrollToSection("map")} to="#">Spots</Link></li>
                <li><Link className='hover:text-sky-500' onClick={() => scrollToSection("download")} to="#">Download</Link></li>
            </ul>
            <div className='flex flex-row gap-2 flex-shrink-0'>
                {/*
                <Link to="#" className='px-5 py-4 bg-neutral-900 hover:bg-neutral-800 rounded-full text-white flex flex-row items-center text-xs'>
                    <img src={SRC_GOOGLE} className='invert size-4 mr-2' />
                    Android
                </Link>
                <Link to="#" className='px-5 py-4 bg-neutral-900 hover:bg-neutral-800 rounded-full text-white flex flex-row items-center text-xs'>
                    <img src={SRC_APPLE} className='invert size-4 mr-2' />
                    Apple
                </Link>*/}
                <Link onClick={() => scrollToSection("download")} to="#" className='px-5 py-4 bg-neutral-900 hover:bg-neutral-800 rounded-full text-white flex flex-row items-center text-xs'>
                    <img src={SRC_STAR} className='invert size-4 mr-2' alt='' />
                    Get early access
                </Link>
            </div>
        </motion.div>
    )
}


const Hero = () => {
    return (
        <div id="hero" className='w-full h-auto relative text-white overflow-hidden'>
            <img src={SRC_HERO} className='w-full bg-bottom h-full absolute top-0 left-0 object-cover' alt='' />
            <div className='size-full top-0 left-0 z-40 flex flex-col items-center justify-start pt-[180px] relative'>
                <motion.h1 initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0, duration: 0.4 }} className='font-bebas text-6xl md:text-8xl text-center '><span className=''>Your surf</span> experience,<br />all in <span className=''>one tide</span></motion.h1>
                <motion.div initial={{ y: 200, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.2, duration: 0.4 }} className='w-[300px] flex-shrink-0 -mb-10  rounded-[50px] shadow-2xl mt-4 relative'>
                    <img src={SRC_PHONE} className='w-full relative' alt='' />
                    <div className='absolute top-8 left-8 text-white flex flex-row items-center text-xs'>
                        <img src={SRC_TOMAS} className='size-8 rounded-full object-cover mr-2' alt='' />
                        <div>
                            <div>John Percy</div>
                            <div className='text-xxs opacity-40'>Secret Spot</div>
                        </div>
                    </div>
                    <motion.div initial={{ y: 200, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.3, duration: 0.4 }} className='w-full absolute bottom-0 left-0 p-4 md:pb-14 flex flex-row gap-x-2'>
                        <div className='w-[40%] p-6 bg-lime-200 flex-shrink-0 rounded-2xl text-lime-950 relative'>
                            <img src={SRC_WAVE} className='size-7 mb-3' alt='' />
                            <h1 className='font-bebas text-4xl'>14</h1>
                            <div className='text-xs'>Waves</div>

                        </div>
                        <div className='w-[40%] p-6 bg-sky-200 rounded-2xl flex-shrink-0 text-sky-950 relative'>
                            <img src={SRC_TIME} className='size-7 mb-3' alt='' />
                            <h1 className='font-bebas text-4xl'>3:09</h1>
                            <div className='text-xs'>Duration</div>
                        </div>
                        <div className='w-[40%] p-6 bg-orange-200 rounded-2xl flex-shrink-0 text-orange-950 relative'>
                            <img src={SRC_STAR} className='size-7 mb-3' alt='' />
                            <h1 className='font-bebas text-4xl'>4.9</h1>
                            <div className='text-xs'>Conditions</div>
                        </div>
                    </motion.div>

                </motion.div>
            </div>
        </div>
    )
}

const Sessions = () => {
    return (
        <div id="sessions" className='w-full min-h-[600px] md:py-32 py-12 flex items-center justify-center bg-white'>
            <div className='flex md:flex-row flex-col w-full max-w-screen-lg items-center'>
                <div className='w-full md:w-1/2 md:pr-12 p-6'>
                    <h1 className='text-4xl md:text-6xl font-bebas mb-4'><span className='text-sky-500'>Track and share</span> your sessions with friends</h1>
                    <p>Track your wave count, water time, surf conditions and share with your friends. Never miss out on epic session again. Check posts from your friends and know the conditions in real time.</p>
                </div>
                <div className='w-full md:w-1/2 flex items-center justify-center'>
                    <img src={SRC_POST} className='w-2/3 rounded-2xl shadow-2xl' alt='' />
                </div>

            </div>
        </div>
    )
}

const Instructors = () => {
    return (
        <div id="instructors" className='w-full min-h-[600px]  flex items-center justify-center bg-white'>
            <div className='flex md:flex-row flex-col w-full items-center'>
                <div className='w-full md:w-1/2 flex items-center justify-center'>
                    <img src={SRC_INSTRUCTORS} className='w-full m-auto md:rounded-r-3xl' alt='' />
                </div>
                <div className='w-full md:w-1/2 md:px-24 p-6 md:my-0 my-10'>
                    <h1 className='text-4xl md:text-6xl font-bebas mb-4'><span className='text-sky-500'>Find surf instructors</span> and friends to surf with</h1>
                    <p>Make best out of your surf trip! Search for surf instructors and surf buddies in your area to enjoy your sessions.</p>
                </div>


            </div>
        </div>
    )
}

const Map = () => {
    return (
        <div id="map" className='w-full min-h-[600px]  flex items-center justify-center bg-white'>
            <div className='flex md:flex-row flex-col w-full items-center'>
                <div className='w-full md:w-1/2 flex items-center justify-center'>
                    <img src={SRC_MAP} className='w-full m-auto md:rounded-l-3xl' alt='' />
                </div>
                <div className='w-full md:w-1/2 md:px-24 md:my-0 my-10 p-6'>
                    <h1 className='text-4xl md:text-6xl font-bebas mb-4'><span className='text-sky-500'>Live surf reports</span> from real surfers and friends</h1>
                    <p>Rate the conditions for your friends. Share crowd factor, wave size and more. Create spot chats with your friends and share the stoke.</p>
                </div>


            </div>
        </div>
    )
}

const Buy = () => {
    return (
        <div id="buy" className='w-full min-h-[600px]  flex items-center justify-center bg-white'>
            <div className='flex md:flex-row flex-col w-full items-center'>
                <div className='w-full md:w-1/2 flex items-center justify-center md:order-2'>
                    <img src={SRC_BUY} className='w-full m-auto md:rounded-r-3xl' alt='' />
                </div>
                <div className='w-full md:w-1/2 md:px-24 md:order-1 p-6 md:mb-0 mb-10 pt-0'>
                    <h1 className='text-4xl md:text-6xl font-bebas mb-4'><span className='text-sky-500'>Rent or Buy</span> equipment</h1>
                    <p>Plan your surf trip ahead and find local surf shops. You can rent or buy equipment from our certified providers. No more hassle with traveling with surfboards.</p>
                </div>


            </div>
        </div>
    )
}


const Download = () => {
    const [activeClass, setActiveClass] = useState('apple');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        device: 'apple'
    });
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleButtonClick = (platform) => {
        setActiveClass(platform);
        setFormData((prev) => ({ ...prev, device: platform }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        // Basic form validation
        if (!formData.firstName || !formData.lastName || !formData.email) {
            setErrorMessage('Please fill in all the fields.');
            return;
        }

        try {
            setLoading(true);
            setErrorMessage('');
            setSuccessMessage('');

            // POST request to your backend
            const response = await fetch('/api/v1/forms/submit/beta', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok) {
                setSuccessMessage(result.message || 'Thanks for signing up! We will notify you soon.');
                setFormData({ firstName: '', lastName: '', email: '', device: 'apple' });
            } else {
                setErrorMessage(result.error || 'Something went wrong. Please try again later.');
            }
        } catch (error) {
            setErrorMessage('Something went wrong. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div id="download" className="w-full min-h-[600px] flex items-center justify-center bg-white">
            <div className="flex flex-col w-full max-w-3xl mx-auto md:py-32 items-center">
                <h1 className="text-4xl md:text-6xl md:mt-0 mt-10 font-bebas mb-4 max-w-lg text-center">
                    Try it out now!<br />
                    <span className="text-sky-500">It's free!</span>
                </h1>
                <p className="text-center">Enter your details below and get early access to Calistakes.</p>
                <div className="w-full p-6 mx-auto">
                    <div className="max-w-[450px] p-4 w-full bg-slate-200 rounded-2xl mt-8 mx-auto">
                        <div className="flex gap-x-4 flex-row">
                            <input 
                                name="firstName" 
                                placeholder="First Name" 
                                value={formData.firstName} 
                                onChange={handleChange} 
                                className="w-full p-4 bg-white rounded-xl placeholder:text-slate-600" 
                            />
                            <input 
                                name="lastName" 
                                placeholder="Last Name" 
                                value={formData.lastName} 
                                onChange={handleChange} 
                                className="w-full p-4 bg-white rounded-xl placeholder:text-slate-600" 
                            />
                        </div>
                        <input 
                            name="email" 
                            placeholder="Email" 
                            value={formData.email} 
                            onChange={handleChange} 
                            className="flex-1 w-full p-4 mt-4 bg-white rounded-xl placeholder:text-slate-600" 
                        />

                        <div className="flex flex-row items-center mt-4">
                            <div className="w-1/2 flex-shrink-0 flex items-center text-sm">
                                Select your device
                            </div>
                            <div className={`w-full flex-row flex bg-white p-1 rounded-xl relative ${activeClass}`}>
                                <div 
                                    onClick={() => handleButtonClick('apple')} 
                                    className={`w-1/2 h-12 ml-1 rounded-xl relative z-20 flex items-center justify-center cursor-pointer ${activeClass === 'apple' ? 'text-white bg-sky-500' : ''}`}
                                >
                                    Apple
                                </div>
                                <div 
                                    onClick={() => handleButtonClick('android')} 
                                    className={`w-1/2 mr-1 h-12 rounded-xl relative z-20 flex items-center justify-center cursor-pointer ${activeClass === 'android' ? 'text-white bg-sky-500' : ''}`}
                                >
                                    Android
                                </div>
                                <motion.div className={`absolute bottom-0 w-full h-full left-0 p-1 flex-row flex ${activeClass === 'android' ? 'justify-end' : 'justify-start'}`}>
                                    <motion.div layout className="w-1/2 bg-sky-500 rounded-xl h-full" />
                                </motion.div>
                            </div>
                        </div>
                        <button 
                            onClick={handleSubmit} 
                            className="px-5 py-4 w-full bg-neutral-900 hover:bg-neutral-800 rounded-xl mt-4 text-center text-white flex flex-row items-center justify-center text-xs"
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                        {successMessage && <p className="text-green-500 text-center mt-2">{successMessage}</p>}
                        {errorMessage && <p className="text-red-500 text-center mt-2">{errorMessage}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landing