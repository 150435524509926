const TRANSLATER = {

    lang: 'fr',

    //Seo
    seo_title: 'Calistakes - Application Ultime pour les Surfeurs',
    seo_description: '#1 Outils de Surf Mondiaux. Nous facilitons la vie des surfeurs.',

    //Header
    wd_login: 'Connexion',
    wd_signup: 'S\'inscrire',
    wd_logout: 'Se déconnecter',

    //Common
    wd_email: 'Email',
    wd_password: 'Mot de passe',
    wd_confirm_password: 'Confirmer le mot de passe',
    wd_full_name: 'Nom complet',
    wd_first_name: 'Prénom',
    wd_last_name: 'Nom de famille',
    wd_first_last: 'Prénom & Nom',
    wd_login_google: 'Se connecter avec Google',
    wd_signup_google: 'S\'inscrire avec Google',
    wd_case_sensitive: 'Sensible à la casse',
    wd_recover_password: 'Récupération de mot de passe',
    wd_contact_details: 'Détails de contact',
    wd_create_account: 'Créer un compte',

    wd_day: 'jour',
    wd_days: 'jours',
    wd_unit: 'unité',
    wd_units: 'unités',
    wd_price: 'prix',

    //Errors
    error_invalid_email: 'Vous avez inséré une adresse e-mail invalide. Veuillez réessayer',
    error_invalid_credentials: 'Identifiants invalides. L\'email et le mot de passe sont incorrects. Veuillez réessayer',
    error_invalid_fullname: 'Vous devez entrer votre nom complet avec au moins 6 caractères.',
    error_invalid_password: 'Vous devez entrer un mot de passe d\'au moins 6 caractères.',
    error_invalid_confirmation: 'La confirmation du mot de passe ne correspond pas au mot de passe saisi.',

    error_already_exists: 'Un compte existe déjà pour cet email.',

    error_missing_password: 'Vous n\'avez pas saisi votre mot de passe',
    error_missing_fullname: 'Vous n\'avez pas saisi votre nom complet',
    error_missing_phone: 'Vous n\'avez pas saisi votre numéro de téléphone',

    error_invalid_address: 'Vous devez entrer une adresse valide avec numéro de maison, rue et ville.',
    error_invalid_store_settings: 'Vous devez compléter la liste de contrôle pour accéder aux paramètres du magasin',
    error_invalid_card: 'La carte insérée n\'est pas valide. Veuillez réessayer',

    error_not_authorized: {
        t: 'Non Autorisé',
        s: 'Vous n\'êtes pas autorisé à visiter cette page'
    }

};

export default TRANSLATER;
