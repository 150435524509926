const TRANSLATER = {

    lang: 'en',

    //Seo
    seo_title: 'Calistakes - Ultimate App for Surfers',
    seo_description: '#1 Worldwide Surf Tools. We make surfers life easy.',

    //Header
    wd_login: 'Login',
    wd_signup: 'Sign Up',
    wd_logout: 'Log out',

    //Common
    wd_email: 'Email',
    wd_password: 'Password',
    wd_confirm_password: 'Confirm password',
    wd_full_name: 'Full name',
    wd_first_name: 'First name',
    wd_last_name: 'Last name',
    wd_first_last: 'First & Last name',
    wd_login_google: 'Log in with Google',
    wd_signup_google: 'Sign up with Google',
    wd_case_sensitive: 'Case sensitive',
    wd_recover_password: 'Password Recovery',
    wd_contact_details: 'Contact Details',
    wd_create_account: 'Create account',

    wd_day: 'day',
    wd_days: 'days',
    wd_unit: 'unit',
    wd_units: 'units',
    wd_price: 'price',

    //Errors
    error_invalid_email: 'You have inserted an invalid email address. Please try again',
    error_invalid_nickname: 'You have inserted an invalid nickname. Please try again',
    error_invalid_credentials: 'Invalid credentials. Nickname and password are incorrect. Please try again ',
    error_invalid_fullname: 'You must enter your full name with at least 6 characters.',
    error_invalid_password: 'You must enter a Password of at least 6 characters.',
    error_invalid_confirmation: 'The password confirmation does not match the password entered.',

    error_already_exists: 'Account already exists for this email.',

    error_missing_password: 'You did not enter your password',
    error_missing_fullname: 'You did not enter your full name',
    error_missing_phone: 'You did not enter your phone number',

    error_invalid_address: 'You must enter a valid address with house number, street and city.',
    error_invalid_store_settings: 'You need to complete the checklist to access the store settings',
    error_invalid_card: 'The inserted card is not valid. Please try again',

    error_not_authorized: {
        t: 'Not Authorized',
        s: 'You\'re not authorized to visit this page'
    }

};

export default TRANSLATER;
