import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import SRC_EYE from "../assets/img/eye.svg";

import { InputField } from "./ui/Forms";

import swal from "sweetalert";
import axios from "axios";

import { API_ROOT } from "../api";

import { GetRouteSearchParams } from "../helpers/GeneralHelper";

export const Login = (props) => {
  const navigate = useNavigate();

  const { TRANSLATE, LANGUAGE, location, onStartUser } = props;

  const [isLoading, setLoading] = useState(false);
  const [NICKNAME, setNickname] = useState("");
  const [PASSWORD, setPassword] = useState("");
  const [nicknameError, setNicknameError] = useState("");

  const onKeyUp = (event) => {
    event.preventDefault();

    if (event.keyCode === 13) {
      event.target.blur();
      onLogin();
    }
  };

  const sanitizeNickname = (value) => {
    // Allow only letters, numbers, and underscores
    const validCharacters = value.replace(/[^a-zA-Z0-9_]/g, "");
    if (value !== validCharacters) {
      setNicknameError("Nickname can only contain letters, numbers, and underscores.");
    } else {
      setNicknameError("");
    }
    return validCharacters?.toLowerCase();
  };

  const onLogin = () => {
    const routeParams = GetRouteSearchParams(location?.search);
    const routeNext = routeParams.next || "";

    if (NICKNAME.trim().length < 3) {
      return swal("Login", "Nickname must be at least 3 characters long.", "warning");
    }

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const form = {
      nickname: NICKNAME.trim(),
      password: PASSWORD.trim(),
      timezone: userTimezone,
      lang: LANGUAGE,
    };

    setLoading(true);

    axios
      .post(API_ROOT + "/users/login", form)
      .then((response) => {
        setLoading(false);

        localStorage.setItem("jwt_token", response.data.jwt_token);

        onStartUser();

        if (routeNext) {
          navigate(routeNext);
          return;
        }

        navigate(`/`);
      })
      .catch((error) => {
        setLoading(false);

        if (!error || !error.response) {
          return swal("Login", TRANSLATE.error_invalid_credentials, "warning");
        }

        const error_data = error.response.data;
        const error_message = error_data.error;

        if (error_message === "missing_password") {
          return swal("Login", TRANSLATE.error_missing_password, "warning");
        } else if (error_message === "missing_nickname") {
          return swal("Login", "You have inserted an invalid nickname. Please try again", "warning");
        }

        return swal("Login", TRANSLATE.error_invalid_credentials, "warning");
      });
  };

  return (
    <>
      <div className="fixed flex flex-col bg-zinc-800 text-white h-full w-full z-[9999] top-0 left-0">
        <div className="w-full h-[68px] flex bg-zinc-800 text-white justify-between items-center px-global flex-shrink-0">
          <Link to="/" className="font-semibold tracking-wider text-xl text-yellow-500">
            CALI<span className="text-white">STAKES</span>
          </Link>
          <Link to="/signup" className="text-yellow-500 font-semibold text-sm">
            Create account
          </Link>
        </div>
        <div className="w-full p-8 h-full overflow-auto items-center justify-center flex flex-col">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full mb-12 flex-col gap-3 items-center justify-center text-center"
          >
            <h1 className="text-4xl font-semibold">Welcome back!</h1>
            <h3 className="text-lg text-slate-400">Please login to continue</h3>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            className="w-full gap-3 flex flex-col"
          >
            {/* Nickname Input */}
            <InputField
              label="Nickname"
              inputProps={{
                value: NICKNAME,
                placeholder: "Type Here",
                onChange: (e) => {
                  const sanitizedValue = sanitizeNickname(e.target.value);
                  setNickname(sanitizedValue);
                },
                onKeyUp: (e) => onKeyUp(e),
              }}
            />
            {/* Display nickname error */}
            {nicknameError && <p className="text-red-500 text-sm">{nicknameError}</p>}

            {/* Password Input */}
            <InputField
              label="Password"
              id="password"
              hasIcon="true"
              icon={SRC_EYE}
              inputProps={{
                value: PASSWORD,
                placeholder: "Type Here",
                onChange: (e) => setPassword(e.target.value),
                onKeyUp: (e) => onKeyUp(e),
              }}
            />

            {isLoading ? (
              <div className="w-full rounded-xl bg-zinc-500 text-white shadow-2xl p-4 text-center mt-3">
                Loading...
              </div>
            ) : (
              <div
                className="w-full rounded-xl bg-yellow-500 text-white shadow-2xl p-4 text-center mt-3"
                onClick={() => onLogin()}
              >
                Log in
              </div>
            )}
          </motion.div>
        </div>
      </div>
    </>
  );
};