const TRANSLATER = {

    lang: 'it',

    //Seo
    seo_title: 'Calistakes - App Definitiva per i Surfisti',
    seo_description: '#1 Strumenti di Surf a Livello Mondiale. Rendiamo la vita dei surfisti più facile.',

    //Header
    wd_login: 'Accedi',
    wd_signup: 'Iscriviti',
    wd_logout: 'Esci',

    //Common
    wd_email: 'Email',
    wd_password: 'Password',
    wd_confirm_password: 'Conferma password',
    wd_full_name: 'Nome completo',
    wd_first_name: 'Nome',
    wd_last_name: 'Cognome',
    wd_first_last: 'Nome & Cognome',
    wd_login_google: 'Accedi con Google',
    wd_signup_google: 'Iscriviti con Google',
    wd_case_sensitive: 'Sensible alle maiuscole',
    wd_recover_password: 'Recupero Password',
    wd_contact_details: 'Dettagli di contatto',
    wd_create_account: 'Crea account',

    wd_day: 'giorno',
    wd_days: 'giorni',
    wd_unit: 'unità',
    wd_units: 'unità',
    wd_price: 'prezzo',

    //Errors
    error_invalid_email: 'Hai inserito un indirizzo email non valido. Riprova per favore',
    error_invalid_credentials: 'Credenziali non valide. L\'email e la password non sono corrette. Riprova per favore',
    error_invalid_fullname: 'Devi inserire il tuo nome completo con almeno 6 caratteri.',
    error_invalid_password: 'Devi inserire una password di almeno 6 caratteri.',
    error_invalid_confirmation: 'La conferma della password non corrisponde alla password inserita.',

    error_already_exists: 'Esiste già un account con questo indirizzo email.',

    error_missing_password: 'Non hai inserito la tua password',
    error_missing_fullname: 'Non hai inserito il tuo nome completo',
    error_missing_phone: 'Non hai inserito il tuo numero di telefono',

    error_invalid_address: 'Devi inserire un indirizzo valido con numero civico, via e città.',
    error_invalid_store_settings: 'Devi completare la checklist per accedere alle impostazioni del negozio',
    error_invalid_card: 'La carta inserita non è valida. Riprova per favore',

    error_not_authorized: {
        t: 'Non Autorizzato',
        s: 'Non sei autorizzato a visitare questa pagina'
    }

};

export default TRANSLATER;
