import React, { useEffect, useState } from "react";
import { useLocation, Routes, Route, Navigate, Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { Social } from './components/Social'
import { MyBets} from "./pages/MyBets";
import { Login } from "./components/Login";
import { Signup } from "./components/Signup";
import { Recovery } from "./components/Recovery";
import { Logout } from "./components/Logout";
import { postApiPrivate } from "./api";

import Landing from "./components/Landing";

import swal from 'sweetalert';

import { HelmetProvider } from 'react-helmet-async';

import LANG_IT from './translations/it';
import LANG_EN from './translations/en';
import LANG_FR from './translations/fr';
import LANG_ES from './translations/es';
import { ApertureOutline } from "react-ionicons";
import Dashboard from "./pages/Dashboard";

const isProd = true;

if (isProd) {
  console.log = () => { };
}

const helmetContext = {};



const AnimatedMaster = (props) => {
  return (
    <HelmetProvider context={helmetContext}>
        <AnimatedRoutes {...props} />
    </HelmetProvider>
  )
}

const AnimatedRoutes = (props) => {

  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [isNativeApp, setNativeApp] = useState(false);
  const [USER, setUser] = useState(null);
  const [LANGUAGE, setLanguage] = useState('en');
  const [CURRENCY, setCurrency] = useState('usd');

  const [MC, setMC] = useState(0);
  const [NC, setNC] = useState(0);

  const [isModalUser, setModalUser] = useState(false);

  const [isModalSearch, setModalSearch] = useState(false);
  const [isModalCart, setModalCart] = useState(false);
  const [isModalLocalisation, setModalLocalisation] = useState(false);
  const [isModalGuide, setModalGuide] = useState(false);
  const [isModalCity, setModalCity] = useState(false);
  const [isModalPosts, setModalPosts] = useState(false);
  const [isModalAvatar, setModalAvatar] = useState(false);
  const [isModalWatch, setModalWatch] = useState(false);

  useEffect(() => {
    onStartUser();
  }, []);

  useEffect(() => {
    localStorage.setItem('language', LANGUAGE);
  }, [LANGUAGE]);

  useEffect(() => {

    const isApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
    setNativeApp(isApp)

  }, [window.isNativeApp])


  const onStartUser = async (isReset) => {

    if (isReset) {
      onUpdateUser({});
      return
    }

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    if (JWT) {

      onFetchUser(JWT);
    }
    else {
      setLoading(false);
    }

  }

  const onFetchUser = async (jwt, payload) => {

    if (payload) {
      onUpdateUser(payload);
      return
    }

    if (!jwt) {
      console.log("ERROR NO JWT FOUND ---> On Fetch User");

      alert('Check your internet connection and try again. 3');

      return
    }

    setLoading(true);

    try {

      const DATA_RESPONSE = await postApiPrivate('/users/profile', {}, jwt);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API USER INFO", DATA_RESPONSE.error);

        swal('Warning', 'You have been disconnected', 'warning')

        if (isProd) {
          localStorage.removeItem('jwt_token');
        }
        setLoading(false);
        onStartUser();

        return

      }

      const DATA_USER = DATA_RESPONSE || {};
      const DATA_MESSAGES = DATA_USER.mc || 0;
      const DATA_NOTIFICATIONS = DATA_USER.nc || 0;

      setMC(DATA_MESSAGES);
      setNC(DATA_NOTIFICATIONS);

      console.log("CHECKING USER PROFILE === ", DATA_USER);

      onUpdateUser(DATA_USER);
      setLoading(false);
      //alert('ALERT ===> '+JSON.stringify(DATA_USER))

    }
    catch (e) {

      console.log("FETCH USER ERROR ===> ", e);
      setLoading(false);
      // if(match?.url){
      //   window.location.replace('/' + LANGUAGE + '/login?next='+match?.url)
      // }
      // else{
      //   window.location.replace('/' + LANGUAGE + '/login')
      // }



      //alert('Account Disconnected ===> '+JSON.stringify(e, null, 2));
      return //alert('Check your internet connection and try again. 5');
    }

  }

  const onUpdateUser = (user) => {
    setUser(user);
  }

  const onUpdateLanguage = (language) => {
    setLanguage(language)
  }

  const onFetchCounts = (type, value) => {
    if (type === 'mc') {
      setMC(value);
    }
    else if (type === 'nc') {
      setNC(value);
    }
  }

  //TRACKING
  const onFetchAnalytics = ({ location }) => {

    console.log("ANALYTICS ===> ");

    // ReactGA.addTrackers(
    //   [
    //     {
    //       trackingId: 'UA-67700835-1',
    //       gaOptions: {
    //         name: 'tracker2'
    //       }
    //     }
    //   ],
    //   { debug: true, alwaysSendToDefaultTracker: false }
    // );

    // ReactGA.set({ page: location.pathname }, ['tracker2']);
    // ReactGA.pageview(location.pathname, ['tracker2']);

  }

  const onFacebookTracking = () => {

  }

  const onGoogleTracking = (event) => {

    // ReactGA.event({
    //   category: event,
    //   action: event
    // });

  }


  let TRANSLATE = {};

  if (LANGUAGE === 'it') {
    TRANSLATE = LANG_IT;
  }
  else if (LANGUAGE === 'en') {
    TRANSLATE = LANG_EN;
  }
  else if (LANGUAGE === 'fr') {
    TRANSLATE = LANG_FR;
  }
  else if (LANGUAGE === 'es') {
    TRANSLATE = LANG_ES;
  }
  else {
    TRANSLATE = LANG_EN
  }

  const sharedProps = {
    ...props,
    USER,
    LANGUAGE,
    CURRENCY,
    TRANSLATE,
    MC,
    NC,

    onFetchUser,
    onStartUser,
    onUpdateUser,
    onFetchCounts,

    onFetchAnalytics,
    onGoogleTracking,
    onFacebookTracking,
    onUpdateLanguage,

    isModalCart,
    isModalGuide,
    isModalLocalisation,
    isModalSearch,
    isModalUser,
    isModalCity,
    isModalPosts,
    isModalAvatar,
    isModalWatch,

    setModalCart,
    setModalGuide,
    setModalLocalisation,
    setModalSearch,
    setModalUser,
    setModalCity,
    setModalPosts,
    setModalAvatar,
    setModalWatch,

    isNativeApp

  }


  //PRIVATE URLS
  if (USER && USER.id) {
    return (
      <>
        <div className="w-full mx-auto h-full flex flex-col bg-zinc-800 justify-between">

          {/* <HeaderSocial /> */}

          <div className="w-full h-full flex relative bg-zinc-800">
            <AnimatePresence mode="sync">
              <Routes location={location} key={location.pathname}>

                <Route path="/" element={<Social location={location} {...sharedProps} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/logout" element={<Logout location={location} {...sharedProps} />} />
                <Route path="/mybets" element={<MyBets location={location} {...sharedProps} />} />
                <Route path="/dashboard" element={<Dashboard location={location} {...sharedProps} />} />
                <Route path="/dashboard/players" element={<Dashboard location={location} {...sharedProps} />} />
                <Route path="/dashboard/bets" element={<Dashboard location={location} {...sharedProps} />} />
                <Route path="/dashboard/recharges" element={<Dashboard location={location} {...sharedProps} />} />

                <Route path="/*" element={<Navigate to="/" />} />

              </Routes>
            </AnimatePresence>
          </div>
        </div>
      </>
    );
  }

  //PUBLIC URLS
  return (
    <>
      <div className="w-full mx-auto h-full flex flex-col bg-zinc-800 justify-between ">

        <div className={`w-full flex relative bg-zinc-800 ${isLoading ? 'h-full' : ''}`}>
          <AnimatePresence mode="sync">
            {isLoading ? (
              <Routes location={location} key={location.pathname}>
                <Route path="/*" element={
                  <div className="w-full h-full flex items-center justify-center">
                    <Link className='w-[50px] h-[50px] flex items-center justify-center rounded-xl bg-yellow-500 text-white shadow-2xl p-4 text-center mt-3 animate-spin'>
                      <ApertureOutline color={'#ffffff'} />
                    </Link>
                  </div>
                } />
              </Routes>
            ) : (
              <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Landing location={location} {...sharedProps} />} />
                <Route path="/login" element={<Login location={location} {...sharedProps} />} />
                <Route path="/signup" element={<Signup location={location} {...sharedProps} />} />
                <Route path="/recovery" element={<Recovery location={location} {...sharedProps} />} />
                <Route path="/logout" element={<Logout location={location} {...sharedProps} />} />
                <Route path="/*" element={<Navigate to="/login" />} />

              </Routes>
            )}
          </AnimatePresence>

        </div>

      </div>

    </>
  );
};

export default AnimatedMaster;
