import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { numberWithCommas } from '../helpers/GeneralHelper';
import { motion } from 'framer-motion';
import { API_SIMPLE } from '../api'

const Dashboard = (props) => {
    const { USER, onFetchUser } = props;
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.pathname);

    if (!USER.store) {
        return (
            <motion.div
                layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 100, scale: 1 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className="page-wrapper p-0 no-scrollbar"
            >
                <div className="bg-zinc-800 text-white min-h-screen">
                    <Header USER={USER} />
                    <div className="w-full h-full flex items-center justify-center min-h-[300px]">
                        <h1>Not Authorized</h1>
                    </div>
                </div>
            </motion.div>
        );
    }

    return (
        <motion.div
            className="page-wrapper p-0 no-scrollbar"
        >
            <div className="bg-zinc-800 text-white min-h-screen">
                <Header USER={USER} isStore />
                <div className="container mx-auto px-4 py-6">
                    <div className='flex flex-row items-center'>
                        {USER.store.photo && (
                            <img src={USER.store.photo} className='w-[100px] h-[100px] object-contain mr-2' alt='' />
                        )}
                        <div className='flex flex-col'>
                            <h1 className="text-3xl font-bold">
                                {USER.store.name}
                            </h1>
                            <h1 className="text-xl text-sky-500 font-bold mb-4">
                                @{USER.store.nickname}
                            </h1>
                        </div>
                    </div>

                    <nav className="w-full border-b border-neutral-700 flex items-center justify-start text-sm mb-6">
                        <TabNavLink label="Overview" url="/dashboard" isActive={activeTab} setActiveTab={setActiveTab} />
                        <TabNavLink label="Players" url="/dashboard/players" isActive={activeTab} setActiveTab={setActiveTab} />
                        <TabNavLink label="Bets" url="/dashboard/bets" isActive={activeTab} setActiveTab={setActiveTab} />
                        <TabNavLink label="Recharges" url="/dashboard/recharges" isActive={activeTab} setActiveTab={setActiveTab} />
                    </nav>
                    <div>
                        {activeTab === '/dashboard' && <OverviewTab USER={USER} />}
                        {activeTab === '/dashboard/players' && <PlayersTab store={USER.store} onFetchUser={onFetchUser} />}
                        {activeTab === '/dashboard/bets' && <BetsTab store={USER.store} />}
                        {activeTab === '/dashboard/recharges' && <RechargesTab store={USER.store} />}
                    </div>
                </div>
            </div>
        </motion.div>

    );
};

// Reusable Header Component
const Header = ({ USER, isStore }) => (
    <div className="w-full h-[68px] flex bg-zinc-800 text-white justify-between items-center px-global flex-shrink-0">
        <Link to="/" className="font-semibold tracking-wider text-xl text-yellow-500">
            CALI<span className="text-white">STAKES</span>
        </Link>
        {isStore ? (
            <div className="flex">
                <div className="flex flex-col-reverse items-end">
                    <Link to="/dashboard" className="text-yellow-500 font-semibold text-xl">
                        {numberWithCommas(USER.store.balance, 'usd')}
                    </Link>
                    <Link to="/dashboard" className="text-sky-500 font-semibold text-sm">@{USER.store.nickname}</Link>
                </div>
            </div>
        ) : USER ? (
            <div className="flex">
                <div className="flex flex-col-reverse items-end">
                    <Link to="/mybets" className="text-yellow-500 font-semibold text-xl">
                        {numberWithCommas(USER.balance, 'usd')}
                    </Link>
                    <Link to="/mybets" className="text-sky-500 font-semibold text-sm">@{USER.nickname}</Link>
                </div>
            </div>
        ) : (
            <Link to="/login" className="text-yellow-500 font-semibold text-sm">
                Login
            </Link>
        )}
    </div>
);

// Navigation Tab Link Component
const TabNavLink = ({ label, url, isActive, setActiveTab }) => (
    <Link
        to={url}
        onClick={() => setActiveTab(url)}
        className={`px-4 py-2 border-b-2 ${isActive === url ? 'border-neutral-700' : 'border-transparent opacity-60 hover:opacity-100'
            }`}
    >
        {label}
    </Link>
);

const OverviewTab = ({ USER }) => {
    const [analytics, setAnalytics] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAnalytics = async () => {
            try {
                const response = await fetch(`${API_SIMPLE}/api/v1/stores/${USER.store.id}/analytics`);
                const data = await response.json();
                setAnalytics(data.analytics || {});
            } catch (error) {
                console.error("Error fetching analytics:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAnalytics();
    }, [USER.store.id]);

    if (loading) return <p>Loading analytics...</p>;

    return (
        <div className="bg-zinc-800 text-white p-6 rounded-md shadow-md">
            <h2 className="text-xl font-bold mb-4">Overview</h2>
            <p className="text-sm text-gray-400 mb-6">
                Welcome to your store dashboard! Here you can manage players, monitor bets, and handle recharges.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <AnalyticsCard
                    title="Total Players"
                    value={analytics.totalPlayers || 0}
                    description="Players onboarded by your store."
                />
                <AnalyticsCard
                    title="Total Bets"
                    value={analytics.totalBets || 0}
                    description="Bets placed by players."
                />
                <AnalyticsCard
                    title="Total Revenue"
                    value={`$${analytics.totalRevenue?.toLocaleString() || 0}`}
                    description="Revenue from bets."
                />
                <AnalyticsCard
                    title="Total Recharges"
                    value={analytics.totalRecharges || 0}
                    description="Recharges processed."
                />
                <AnalyticsCard
                    title="Total Recharge Amount"
                    value={`$${analytics.totalRechargeAmount?.toLocaleString() || 0}`}
                    description="Amount recharged to players."
                />
            </div>
        </div>
    );
};

// Analytics Card Component
const AnalyticsCard = ({ title, value, description }) => (
    <div className="bg-gray-700 p-4 rounded-md shadow-md">
        <h3 className="text-lg font-bold">{title}</h3>
        <p className="text-2xl font-semibold text-yellow-500">{value}</p>
        <p className="text-sm text-gray-400">{description}</p>
    </div>
);

//Players Tab
const PlayersTab = ({ store, onFetchUser }) => {
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isRechargeModalOpen, setIsRechargeModalOpen] = useState(false);
    const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null); // For recharge
    const [rechargeAmount, setRechargeAmount] = useState(""); // Recharge input
    const [newUser, setNewUser] = useState({
        name: "",
        password: "",
        balance: "",
    }); // New user creation state

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const response = await fetch(
                    `${API_SIMPLE}/api/v1/stores/${store.id}/players`
                );
                const data = await response.json();
                setPlayers(data.players || []);
            } catch (error) {
                console.error("Error fetching players:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPlayers();
    }, [store.id]);

    const handleRecharge = async () => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        try {
            const response = await fetch(
                `${API_SIMPLE}/api/v1/stores/${store.id}/recharges`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        playerId: selectedPlayer.id,
                        amount: parseFloat(rechargeAmount),
                    }),
                }
            );
            const data = await response.json();
            if (data.success) {
                //alert("Recharge successful!");
                setPlayers((prev) =>
                    prev.map((player) =>
                        player.id === selectedPlayer.id
                            ? { ...player, balance: player.balance + parseFloat(rechargeAmount) }
                            : player
                    )
                );
                setIsRechargeModalOpen(false);
                setRechargeAmount("");
            } else {
                alert("Recharge failed: " + data.message);
            }
        } catch (error) {
            console.error("Error recharging player:", error.message);
            alert("Error processing recharge. Please try again.");
        } finally {
            onFetchUser(JWT)
        }
    };

    const handleCreateUser = async () => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        try {
            const response = await fetch(
                `${API_SIMPLE}/api/v1/stores/${store.id}/players`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name: newUser.name,
                        password: newUser.password,
                        balance: parseFloat(newUser.balance),
                    }),
                }
            );
            const data = await response.json();
            if (data.success) {
                //alert("User created successfully!");
                setPlayers((prev) => [data.player, ...prev]);
                setIsCreateUserModalOpen(false);
                setNewUser({ name: "", password: "", balance: "" });
            } else {
                alert("User creation failed: " + data.message);
            }
        } catch (error) {
            console.error("Error creating user:", error.message);
            alert("Error creating user. Please try again.");
        } finally {
            onFetchUser(JWT)
        }
    };

    if (loading) return <p>Loading players...</p>;

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Players</h2>
            <button
                className="mb-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
                onClick={() => setIsCreateUserModalOpen(true)}
            >
                Create New Player
            </button>
            <table className="table-auto w-full border-collapse border border-gray-700 text-sm">
                <thead className="bg-gray-800 text-gray-400">
                    <tr>
                        <th className="border border-gray-700 p-4 text-left">Name</th>
                        <th className="border border-gray-700 p-4 text-right">Balance</th>
                        <th className="border border-gray-700 p-4 text-center">Total Bets</th>
                        <th className="border border-gray-700 p-4 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((player) => (
                        <tr key={player.id}>
                            <td className="border border-gray-700 p-4">{player.name}</td>
                            <td className="border border-gray-700 p-4 text-right">
                                {numberWithCommas(player.balance, "usd")}
                            </td>
                            <td className="border border-gray-700 p-4 text-center">
                                {player.totalBets}
                            </td>
                            <td className="border border-gray-700 p-4 text-center">
                                <button
                                    className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded mr-2"
                                    onClick={() => {
                                        setSelectedPlayer(player);
                                        setIsRechargeModalOpen(true);
                                    }}
                                >
                                    Recharge
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Recharge Modal */}
            {isRechargeModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 w-11/12 md:w-1/3">
                        <h3 className="text-xl font-bold mb-4">Recharge Player</h3>
                        <p className="mb-4">
                            <strong>Player:</strong> {selectedPlayer.name}
                        </p>
                        <div className="mb-4">
                            <label className="block mb-2">Amount:</label>
                            <input
                                type="number"
                                className="w-full px-4 py-2 bg-gray-700 text-white rounded"
                                value={rechargeAmount}
                                onChange={(e) => setRechargeAmount(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-between">
                            <button
                                onClick={handleRecharge}
                                className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded text-white"
                            >
                                Confirm Recharge
                            </button>
                            <button
                                onClick={() => setIsRechargeModalOpen(false)}
                                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Create User Modal */}
            {isCreateUserModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 w-11/12 md:w-1/3">
                        <h3 className="text-xl font-bold mb-4">Create New Player</h3>
                        <div className="mb-4">
                            <label className="block mb-2">Name:</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 bg-gray-700 text-white rounded"
                                value={newUser.name}
                                onChange={(e) =>
                                    setNewUser((prev) => ({ ...prev, name: e.target.value }))
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Password:</label>
                            <input
                                type="password"
                                className="w-full px-4 py-2 bg-gray-700 text-white rounded"
                                value={newUser.password}
                                onChange={(e) =>
                                    setNewUser((prev) => ({ ...prev, password: e.target.value }))
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Balance:</label>
                            <input
                                type="number"
                                className="w-full px-4 py-2 bg-gray-700 text-white rounded"
                                value={newUser.balance}
                                onChange={(e) =>
                                    setNewUser((prev) => ({ ...prev, balance: e.target.value }))
                                }
                            />
                        </div>
                        <div className="flex justify-between">
                            <button
                                onClick={handleCreateUser}
                                className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded text-white"
                            >
                                Create Player
                            </button>
                            <button
                                onClick={() => setIsCreateUserModalOpen(false)}
                                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};


// Bets Tab
const BetsTab = ({ store }) => {
    const [bets, setBets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBets = async () => {
            try {
                const response = await fetch(`${API_SIMPLE}/api/v1/stores/${store.id}/bets`);
                const data = await response.json();

                if (data.success) {
                    setBets(data.bets || []);
                }
            } catch (error) {
                console.error("Error fetching bets:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBets();
    }, [store.id]);

    if (loading) return <p>Loading bets...</p>;

    if (!bets.length) return <p>No bets available for this store.</p>;

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Store Bets</h2>
            <table className="table-auto w-full border-collapse border border-gray-700 text-sm">
                <thead className="bg-gray-800 text-gray-400">
                    <tr>
                        <th className="border border-gray-700 p-4 text-left">Player</th>
                        <th className="border border-gray-700 p-4 text-left">Game</th>
                        <th className="border border-gray-700 p-4 text-center">Market</th>
                        <th className="border border-gray-700 p-4 text-center">Outcome</th>
                        <th className="border border-gray-700 p-4 text-right">Bet Amount</th>
                        <th className="border border-gray-700 p-4 text-right">Payout</th>
                        <th className="border border-gray-700 p-4 text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {bets.map((bet) => (
                        <tr key={bet.id} className="hover:bg-gray-800">
                            <td className="border border-gray-700 p-4">{bet.player}</td>
                            <td className="border border-gray-700 p-4">{bet.game}</td>
                            <td className="border border-gray-700 p-4 text-center">{bet.market}</td>
                            <td className="border border-gray-700 p-4 text-center">{bet.outcome}</td>
                            <td className="border border-gray-700 p-4 text-right">
                                {numberWithCommas(bet.betAmount, "usd")}
                            </td>
                            <td className="border border-gray-700 p-4 text-right">
                                {numberWithCommas(bet.payout, "usd")}
                            </td>
                            <td className="border border-gray-700 p-4 text-center">{bet.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


// Recharges Tab
const RechargesTab = ({ store }) => {
    const [recharges, setRecharges] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRecharges = async () => {
            try {
                const response = await fetch(`${API_SIMPLE}/api/v1/stores/${store.id}/recharges`);
                const data = await response.json();
                setRecharges(data.recharges || []);
            } catch (error) {
                console.error("Error fetching recharges:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRecharges();
    }, [store.id]);

    if (loading) return <p>Loading recharges...</p>;

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Recharges</h2>
            <table className="table-auto w-full border-collapse border border-gray-700 text-sm">
                <thead className="bg-gray-800 text-gray-400">
                    <tr>
                        <th className="border border-gray-700 p-4 text-left">Date</th>
                        <th className="border border-gray-700 p-4 text-left">Player</th>
                        <th className="border border-gray-700 p-4 text-right">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {recharges.map((recharge) => (
                        <tr key={recharge.id} className="hover:bg-gray-800">
                            <td className="border border-gray-700 p-4">
                                {new Date(recharge.date).toLocaleString()}
                            </td>
                            <td className="border border-gray-700 p-4">{recharge.player || "N/A"}</td>
                            <td className="border border-gray-700 p-4 text-right">
                                {numberWithCommas(recharge.amount, "usd")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Dashboard;