const TRANSLATER = {

    lang: 'es',

    //Seo
    seo_title: 'Calistakes - La Mejor Aplicación para Surfistas',
    seo_description: '#1 Herramientas de Surf a Nivel Mundial. Hacemos la vida de los surfistas más fácil.',

    //Header
    wd_login: 'Iniciar sesión',
    wd_signup: 'Registrarse',
    wd_logout: 'Cerrar sesión',

    //Common
    wd_email: 'Correo electrónico',
    wd_password: 'Contraseña',
    wd_confirm_password: 'Confirmar contraseña',
    wd_full_name: 'Nombre completo',
    wd_first_name: 'Nombre',
    wd_last_name: 'Apellido',
    wd_first_last: 'Nombre & Apellido',
    wd_login_google: 'Iniciar sesión con Google',
    wd_signup_google: 'Registrarse con Google',
    wd_case_sensitive: 'Sensible a mayúsculas',
    wd_recover_password: 'Recuperación de contraseña',
    wd_contact_details: 'Detalles de contacto',
    wd_create_account: 'Crear cuenta',

    wd_day: 'día',
    wd_days: 'días',
    wd_unit: 'unidad',
    wd_units: 'unidades',
    wd_price: 'precio',

    //Errors
    error_invalid_email: 'Has insertado una dirección de correo electrónico no válida. Por favor, inténtalo de nuevo',
    error_invalid_credentials: 'Credenciales inválidas. El correo electrónico y la contraseña son incorrectos. Por favor, inténtalo de nuevo',
    error_invalid_fullname: 'Debes ingresar tu nombre completo con al menos 6 caracteres.',
    error_invalid_password: 'Debes ingresar una contraseña de al menos 6 caracteres.',
    error_invalid_confirmation: 'La confirmación de la contraseña no coincide con la contraseña ingresada.',

    error_already_exists: 'Ya existe una cuenta con este correo electrónico.',

    error_missing_password: 'No has ingresado tu contraseña',
    error_missing_fullname: 'No has ingresado tu nombre completo',
    error_missing_phone: 'No has ingresado tu número de teléfono',

    error_invalid_address: 'Debes ingresar una dirección válida con número de casa, calle y ciudad.',
    error_invalid_store_settings: 'Debes completar la lista de verificación para acceder a los ajustes de la tienda',
    error_invalid_card: 'La tarjeta insertada no es válida. Por favor, inténtalo de nuevo',

    error_not_authorized: {
        t: 'No Autorizado',
        s: 'No estás autorizado para visitar esta página'
    }

};

export default TRANSLATER;
