import { useState, useEffect } from "react";
import axios from "axios";
import { API_SIMPLE } from "../api";
import { Link } from "react-router-dom";
import { numberWithCommas } from "../helpers/GeneralHelper";
import swal from 'sweetalert';

// import SRC_IMG from '../assets/img/calistakes-logo.png'

const LandingPage = (props) => {

  const { USER, onFetchUser } = props;

  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBet, setSelectedBet] = useState(null); // Store selected bet
  const [betAmount, setBetAmount] = useState(""); // Bet amount
  const [modalOpen, setModalOpen] = useState(false); // Modal visibility
  const [selectedBookmaker, setSelectedBookmaker] = useState("BetMGM"); // Selected bookmaker

  // Fetch games on page load
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(API_SIMPLE + "/api/v1/games"); // Replace with your API endpoint
        setGames(response.data);

        // // Set default bookmaker to the first one in the list
        // if (response.data.length > 0 && response.data[0]?.odds?.length > 0) {
        //   setSelectedBookmaker(response.data[0].odds[0].title);
        // }
      } catch (error) {
        console.error("Error fetching games:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  // Calculate payout based on American odds
  const calculatePayout = () => {
    if (!betAmount || !selectedBet?.price) return 0;

    const odds = selectedBet.price;

    // Positive odds (e.g., +150)
    if (odds > 0) {
      return (parseFloat(betAmount) * (odds / 100 + 1)).toFixed(2);
    }

    // Negative odds (e.g., -120)
    if (odds < 0) {
      return (parseFloat(betAmount) * (100 / Math.abs(odds) + 1)).toFixed(2);
    }

    return 0;
  };

  const onPlaceBet = async () => {
    if (!selectedBet || !betAmount) {
      swal("Error", "Please select a bet and enter a valid amount.", "warning");
      return;
    }

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    try {
      const response = await axios.post(`${API_SIMPLE}/api/v1/bets/place`, {
        gameId: selectedBet.gameId,
        market: selectedBet.market,
        outcome: selectedBet.outcome,
        point: selectedBet.point,
        price: selectedBet.price,
        betAmount: parseFloat(betAmount),
      }, { headers: { authorization: JWT } });

      // console.log("ALERT "+JSON.stringify(response, null, 2))
      if (response.data.success) {
        swal("Bet Placed", "Your bet has been successfully placed!", "success");
        setModalOpen(false);
        setBetAmount(""); // Reset the bet amount
        onFetchUser(JWT)
      } else {
        swal("Warning", response.data.message || "Failed to place bet.", "error");
      }
    } catch (error) {
      console.error("Error placing bet:", error.message);
      swal("Error", error.message || "Something went wrong. Please try again later.", "error");
    }
  };

  const calculateGameMinute = (startTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const elapsed = Math.floor((now - start) / 60000); // Difference in minutes
    return elapsed > 0 ? elapsed : 0; // Ensure no negative minutes
  };


  return (
    <div className="bg-zinc-800 text-white min-h-screen">

      <div className='w-full h-[68px] flex bg-zinc-800 text-white justify-between items-center px-global flex-shrink-0'>
        <Link to="/" className="font-semibold tracking-wider text-xl text-yellow-500">CALI<span className='text-white'>STAKES</span></Link>
        {USER ? (
          <div className="flex">
            <div className="flex flex-col-reverse items-end">
              <Link to="/mybets" className='text-yellow-500 font-semibold text-xl'>{numberWithCommas(USER.balance, 'usd')}</Link>
              <Link to="/mybets" className='text-sky-500 font-semibold text-sm'>{USER.nickname}</Link>
            </div>
            {/* <Link to="/profile" className='text-yellow-500font-semibold text-sm'>My Bets</Link> */}
          </div>
        ) : (
          <Link to="/login" className="text-yellow-500 font-semibold text-sm">
            Login
          </Link>
        )}
      </div>

      <section className="py-12 bg-zinc-800">
        <div className="container mx-auto px-4">
          {/* <div className="flex items-center justify-center">
            <img src={SRC_IMG} className="w-[300px] h-[200px] object-contain" alt="" />
          </div> */}
          <h2 className="text-4xl text-center font-bold mb-10">Upcoming Games</h2>

          {/* <pre>
            {JSON.stringify(USER, null, 2)}
          </pre> */}
          {loading ? (
            <p>Loading games...</p>
          ) : games.length === 0 ? (
            <p>No games available at the moment.</p>
          ) : (
            <div>
              {/* Bookmaker Selector */}
              <div className="mb-6">
                <label htmlFor="bookmaker" className="block text-lg font-bold mb-2">
                  Select Bookmaker:
                </label>
                <select
                  id="bookmaker"
                  value={selectedBookmaker}
                  onChange={(e) => setSelectedBookmaker(e.target.value)}
                  className="w-full p-3 bg-gray-800 text-white rounded"
                >
                  {games[0]?.odds.map((bookmaker) => (
                    <option key={bookmaker.title} value={bookmaker.title}>
                      {bookmaker.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse border border-gray-700 text-sm">
                  <thead className="bg-gray-800 text-gray-400">
                    <tr>
                      <th className="border border-gray-700 p-4 text-left">Teams</th>
                      <th className="border border-gray-700 p-4 text-center">Score</th>
                      <th className="border border-gray-700 p-4 text-center">Spread</th>
                      <th className="border border-gray-700 p-4 text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {games.map((game) => (
                      <tr key={game.gameId} className="hover:bg-gray-800">

                        {/* Game */}
                        <td className="border border-gray-700 p-4">
                          {game.status === "upcoming" ? (
                            <p className="text-yellow-500 mb-2">{new Date(game.startTime).toLocaleString()}</p>
                          ) : game.status === "live" ? (
                            <p className="text-green-500 mb-2">
                              Live {calculateGameMinute(game.startTime)}'
                            </p>
                          ) : (
                            <p className="text-red-500 mb-2">Finished</p>
                          )}

                          <div className="flex flex-col">
                            <div className="flex items-center">
                              <img src={`/logos/${game.awayLogo}`} alt="" className="w-[40px] h-[40px] object-contain mr-2" />
                              <div className="flex flex-col">
                                <p className="font-bold">{game.awayTeam}</p>
                                <p className="text-slate-400">{game.awayTeamWins || 0}-{game.awayTeamLooses || 0}</p>
                              </div>
                            </div>
                            <div className="flex items-center">
                              <img src={`/logos/${game.homeLogo}`} alt="" className="w-[40px] h-[40px] object-contain mr-2" />
                              <div className="flex flex-col">
                                <p className="font-bold">{game.homeTeam}</p>
                                <p className="text-slate-400">{game.homeTeamWins || 0}-{game.homeTeamLooses || 0}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        {/* Game */}
                        <td className="border border-gray-700 p-4">
                          {/* <p>{new Date(game.startTime).toLocaleString()}</p> */}
                          <div className="flex flex-col">
                            <div className="flex items-center justify-center">
                              <p className="text-lg font-bold ml-1">{game.awayScore || 0}</p>
                            </div>
                            <div className="flex items-center justify-center">
                              <p className="text-lg font-bold ml-1">{game.homeScore || 0}</p>
                            </div>
                          </div>
                        </td>
                        {/* Spread */}
                        <td className="border border-gray-700 p-4 text-center">
                          {game.status !== 'completed' && (
                            game.odds
                              ?.find((bookmaker) => bookmaker.title === selectedBookmaker)
                              ?.markets.find((market) => market.key === "spreads")
                              ?.outcomes.map((outcome, index) => (
                                <button
                                  key={index}
                                  onClick={() => {
                                    setSelectedBet({
                                      gameId: game.gameId,
                                      market: "spreads",
                                      outcome: outcome.name,
                                      point: outcome.point,
                                      price: outcome.price,
                                    });
                                    setModalOpen(true);
                                  }}
                                  className="block w-full px-4 py-2 mb-2 bg-blue-500 hover:bg-blue-600 rounded text-sm"
                                >
                                  {outcome.point > 0 ? `+${outcome.point}` : outcome.point} ({outcome.price > 0 ? `+${outcome.price}` : outcome.price})
                                </button>
                              ))
                          )}

                        </td>
                        {/* Total */}
                        <td className="border border-gray-700 p-4 text-center">
                          {game.status !== 'completed' && (
                            game.odds
                              ?.find((bookmaker) => bookmaker.title === selectedBookmaker)
                              ?.markets.find((market) => market.key === "totals")
                              ?.outcomes.map((outcome, index) => (
                                <button
                                  key={index}
                                  onClick={() => {
                                    setSelectedBet({
                                      gameId: game.gameId,
                                      market: "totals",
                                      outcome: outcome.name,
                                      point: outcome.point,
                                      price: outcome.price,
                                    });
                                    setModalOpen(true);
                                  }}
                                  className="block w-full px-4 py-2 mb-2 bg-blue-500 hover:bg-blue-600 rounded text-sm"
                                >
                                  {outcome.name} {outcome.point} (
                                  {outcome.price > 0 ? `+${outcome.price}` : outcome.price})
                                </button>
                              ))
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </section>

      {/* Bet Modal */}
      {modalOpen && selectedBet && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 w-11/12 md:w-1/3">
            <h3 className="text-xl font-bold mb-4">Quick Bet</h3>
            <p className="mb-4">
              <strong>{selectedBet.market.toUpperCase()}:</strong>{" "}
              {selectedBet.outcome}{" "}
              {selectedBet.point !== undefined && `(${selectedBet.point})`} (
              {selectedBet.price > 0 ? `+${selectedBet.price}` : selectedBet.price}
              )
            </p>
            <div className="mb-4">
              <label className="block mb-2">Bet Amount:</label>
              <input
                type="text"
                inputMode="decimal"
                pattern="[0-9]*"
                className="w-full px-4 py-2 bg-gray-700 text-white rounded"
                value={betAmount}
                onChange={(e) => {
                  // Allow only numerical values
                  const value = e.target.value;
                  if (/^\d*\.?\d*$/.test(value)) {
                    setBetAmount(value);
                  }
                }}
                onKeyDown={(e) => {
                  // Prevent invalid keys
                  if (
                    ["e", "E", "+", "-"].includes(e.key) ||
                    (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== ".")
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <p className="mb-4">
              <strong>Payout:</strong> ${calculatePayout()}
            </p>
            <div className="flex justify-between">

              <button
                onClick={() => setModalOpen(false)}
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white"
              >
                Close
              </button>

              {USER ? (
                <button
                  onClick={onPlaceBet}
                  className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded text-white"
                >
                  Place Bet
                </button>
              ) : (
                <Link
                  to="/login"
                  className="bg-yellow-500 hover:bg-yellow-600 px-4 py-2 rounded text-white"
                >
                  Login to Bet
                </Link>
              )}

            </div>
          </div>
        </div>
      )}

      <footer className="bg-zinc-800 text-center py-10">
        <Link to="/" className="font-semibold tracking-wider text-xl text-yellow-500">CALI<span className='text-white'>STAKES</span></Link>
        <p className="text-sm text-gray-400">
          © {new Date().getFullYear()} Calistakes. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;