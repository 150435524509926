import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { numberWithCommas } from "../helpers/GeneralHelper";
import { API_SIMPLE } from "../api";

export function MyBets(props) {
    const navigate = useNavigate();

    const { USER, LANGUAGE } = props;
    const [bets, setBets] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch user bets
    useEffect(() => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const fetchBets = async () => {
            try {
                const response = await axios.get(`${API_SIMPLE}/api/v1/bets/user`, { headers: { authorization: JWT } });
                setBets(response.data.bets || []);
            } catch (error) {
                console.error("Error fetching bets:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBets();
    }, []);

    return (
        <>

            <motion.div
                layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 100, scale: 1 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className="page-wrapper p-0 no-scrollbar"
            >
                <div className='w-full h-[68px] flex bg-zinc-800 text-white justify-between items-center px-global flex-shrink-0'>
                    <Link to="/" className="font-semibold tracking-wider text-xl text-yellow-500">CALI<span className='text-white'>STAKES</span></Link>
                    {USER ? (
                        <div className="flex">
                            <div className="flex flex-col-reverse items-end">
                                <Link to="/mybets" className='text-yellow-500 font-semibold text-xl'>{numberWithCommas(USER.balance, 'usd')}</Link>
                                <Link to="/mybets" className='text-sky-500 font-semibold text-sm'>{USER.nickname}</Link>
                            </div>
                            {/* <Link to="/profile" className='text-yellow-500font-semibold text-sm'>My Bets</Link> */}
                        </div>
                    ) : (
                        <Link to="/login" className="text-yellow-500 font-semibold text-sm">
                            Login
                        </Link>
                    )}
                </div>
                <div className="w-full h-full overflow-auto no-scrollbar">
                    {/* User Information */}
                    <div className="w-full flex items-start text-sm px-global py-4 pb-4">
                        <div className="flex flex-col">
                            <div className="text-xl font-bold">
                                Balance: {numberWithCommas(USER?.balance, "usd")}
                            </div>
                            <div className="text-base">Nickname: {USER?.nickname}</div>
                        </div>
                    </div>

                    {/* Bets Section */}
                    <div className="w-full px-global py-4">
                        <h1 className="text-lg font-bold mb-4">My Bets</h1>
                        {loading ? (
                            <p>Loading your bets...</p>
                        ) : bets.length === 0 ? (
                            <p>No bets placed yet.</p>
                        ) : (
                            <div className="overflow-x-auto">
                                <table className="table-auto w-full border-collapse border border-gray-700 text-sm">
                                    <thead className="bg-zinc-800 text-gray-400">
                                        <tr>
                                            <th className="border border-gray-700 p-4 text-left">Game</th>
                                            <th className="border border-gray-700 p-4 text-center">Market</th>
                                            <th className="border border-gray-700 p-4 text-center">Point</th>
                                            <th className="border border-gray-700 p-4 text-center">Price</th>
                                            <th className="border border-gray-700 p-4 text-center">Amount</th>
                                            <th className="border border-gray-700 p-4 text-center">Payout</th>
                                            <th className="border border-gray-700 p-4 text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bets.map((bet) => (
                                            <tr key={bet.id} className="hover:bg-zinc-200 cursor-pointer">
                                                <td className="border border-gray-700 p-4">
                                                    {bet.game.awayTeam} vs {bet.game.homeTeam}
                                                </td>
                                                <td className="border border-gray-700 p-4 text-center">
                                                    {bet.market} <div className="font-semibold">({bet.outcome})</div>
                                                </td>
                                                <td className="border border-gray-700 p-4 text-center">
                                                    {bet.point > 0 ? `+${bet.point}` : bet.point}
                                                </td>
                                                <td className="border border-gray-700 p-4 text-center">
                                                    {bet.price > 0 ? `+${bet.price}` : bet.price}
                                                </td>
                                                <td className="border border-gray-700 p-4 text-center">
                                                    {numberWithCommas(bet.betAmount, "usd")}
                                                </td>
                                                <td className="border border-gray-700 p-4 text-center">
                                                    {numberWithCommas(bet.payout, "usd")}
                                                </td>
                                                <td className="border border-gray-700 p-4 text-center capitalize">
                                                    {bet.status === 'won' ? (
                                                        <p className="text-green-500">Won</p>
                                                    ) : bet.status === 'lost' ? (
                                                        <p className="text-red-500">Lost</p>
                                                    ) : (
                                                        <p className="text-yellow-500">Progress</p>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </motion.div>
        </>
    );
}