import { motion } from "framer-motion"
import LandingPage from '../pages/Landing';

export function Social(props) {
    return (
        <motion.div layout
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 100, scale: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className='page-wrapper p-0 no-scrollbar'>
           <LandingPage {...props} />

        </motion.div>
    );
}
